import React, {useEffect, useRef} from 'react';
import {connect} from "react-redux";
// pages import
import Pages from '../pages';

// react router import
import {BrowserRouter as Router, Route, Routes as Switch} from 'react-router-dom';
import ScrollToTop from "../layout/ScrollIntoView";
import {retrieveCollegiaAccessToken} from './Helpers';
import Request from "./Request";
import axios from "axios";
import {useDispatch} from "react-redux";
import {SUBMIT_ENV_PASSWORD} from "../actions/403/types";


/**
 * @returns {unknown[]}
 */
function makeRoutes() {
	const authed = retrieveCollegiaAccessToken();

	const IndexPage = authed ? Pages.MainPage : Pages.Login

	const publicPaths = {
		"/": IndexPage,
		"/home": IndexPage,
		"/index": IndexPage,
		"/homepage": IndexPage,
		"/login": IndexPage,
		"/login/:token": Pages.Login,
		"/select-service": Pages.SelectService,
		"/sign-up": Pages.SignUp,
		"/sign-up/:token": Pages.SignUp,
		"/forgot-password": Pages.ForgotPassword,
		"/sign-up-adviser": Pages.SignUpAdviser,
		"/forgot-password/request-sent": Pages.PasswordRequestSent,
		"/reset-password/:token": Pages.ResetPassword,
		"/select-banks": Pages.DirectDebit.SelectBanks,
		"/select-banks/:collegiaToken": Pages.DirectDebit.SelectBanks,
		"/confirm-monthly-contribution": Pages.DirectDebit.ConfirmMonthlyContributions,
		"/success": Pages.DirectDebit.Success,
		"/403": Pages.Page_403,
	};

	const privatePaths = {
		"/main-page": Pages.MainPage,
		"/main-page/:authToken": Pages.MainPage,
		"/main-page/:tab": Pages.MainPage,
		"/payroll-software": Pages.PayrollSoftware,
		"/active-account": Pages.ActivateAccount,
		"/active-account/:step": Pages.ActivateAccount,
		"/authorise-employer-connection/:code": Pages.Authorization.EmployerConnection,
		"/my-account": Pages.MyAccount,
		"/api-uploads": Pages.ApiUploads,
		"/change-info": Pages.ChangeInfo,
		"/change-address": Pages.ChangeTradingAddress,
		"/change-email": Pages.ChangeEmail,
		"/email-preferences": Pages.EmailPreferences,
		"/change-password": Pages.ChangePassword,
		"/users": Pages.Users,
		"/my-advisors": Pages.MyAdvisors,
		"/my-ifas": Pages.MyIFA,
		"/materials": Pages.Materials,
		"/benefits": Pages.Benefits,
		"/logout": Pages.Logout,
		"/authorise-employer-advisor-connection/:code/:isAdvisor": Pages.Authorization.AdvisorEmployerConnection,
		"/papdis-details/:id": Pages.PapdisDetails,
		"/software-papdis-details": Pages.ApiPapdisDetails,
		"/upload-bank-ddi-file": Pages.UploadBankDdiFile,
		"/invite-employees": Pages.InviteEmployees,
		"/employees-communications/:id": Pages.EmployeesCommunications,
		"/debit-information": Pages.DebitInformation,
	};

	return [
		...Object.entries(publicPaths).map(([path, component]) => (
				<Route exact key={path} path={path} element={React.createElement(component, {})}
				/>
			)
		),

		...Object.entries(privatePaths).map(([path, component]) => (
				<Route
					exact
					key={path}
					path={path}
					element={React.createElement(component, {})}
					authed={authed !== false}
				/>
			)
		),
		<Route key={"404-route"} component={Pages.NotFound}/>
	];
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const AppRoutes: JSX.Element = (
	{
		profile_data = {},
	}
) => {
	useRef("route");
	const dispatch = useDispatch();

	useEffect(() => {
		if (process.env.REACT_APP_ENV !== "production" || process.env.REACT_APP_ENV !== "local") {
			axios.get(`https://api.ipify.org?format=json`)
				.then((response = {
					data: {
						ip: "",
					}
				}) => {
					dispatch({
						type: SUBMIT_ENV_PASSWORD,
						profile_data: {
							...profile_data,
							my_ip: response.data.ip,
						}
					});
					Request.post("employers/is_allowed", {
							my_ip: response.data.ip,
						})
						.catch(error => {
							if (error.response.status === 403) {
								if (window.location.pathname !== "/403") {
									window.location.href = `/${error.response.status}`;
								}
							}
						});
				})
				Request.get("individual/is_allowed")
					.catch(error => {
						if (error?.response) {
							if (error?.response?.status === 403) {
								if (window.location.pathname !== "/403") {
									window.location.href = `/${error.response.status}`;
								}
							}
						} else {
							console.error("An error occurred:", error.message || error);
						}
					});
		}
	});

	return (
		<Router
			basename={"/"}
		>
			<ScrollToTop/>
			<Switch>
				{makeRoutes()}
			</Switch>
		</Router>
	);
};

const mapStateToProps = state => state.Profile;

const mapDispatchToProps = dispatch => ({});


export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);